<template>
    <div class="flex items-center">
        {{ rolDescription(params.value) }}
    </div>
</template>
<script>
export default {
    name : 'CellRendererRol',
    computed : {
        rolDescription() {
            return (value) => {
                let role = this.$store.state.AppData.profileTypes.find(r => r.profile_type == value)
                if (role)
                    return role.profile_type_description
                // if (value === 3) return "Administrador"
                // else if (value === 4) return "Gerente"
                // else if (value === 5) return "Cajero"
                else return ""
            }
        }
    }

}
</script>
<style lang="stylus">

</style>
<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
      <feather-icon icon="RefreshCwIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" v-if="this.params.status == 4"/>
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActions',
        methods: {
          editRecord() {
            // this.$router.push("/views/user/user-edit/" + 268).catch(() => {})
            this.$router.push("/users/user-edit/" + this.params.data.id).catch((error) => {console.log(error)})
            /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose

              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
          },
          confirmDeleteRecord() {
            this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: `Confirmar eliminación`,
              text: `¿Estás seguro que quieres eliminar el usuario "${this.params.data.name + ' ' + this.params.data.last_name}"?`,
              accept: this.deleteRecord,
              acceptText: "Eliminar"
            })
          },
          deleteRecord() {
            
            this.$store.dispatch('userManagement/deleteUser', this.params.data.id)
              .then(response => {
                if(response.status)
                  this.showNotify(false, 'Usuario eliminado', 'Se eliminó el usuario seleccionado')
              })
              .catch(error => this.showNotify('danger', 'Error', error))

            

            /* UnComment below lines for enabling true flow if deleting user */
            // this.$store.dispatch("userManagement/removeRecord", this.params.data.id)
            //   .then(()   => { this.showDeleteSuccess() })
            //   .catch(err => { console.error(err)       })
          },
          showNotify(color = false, title, text) {
            this.$vs.notify({
              time: 8000,
              color: color || 'success',
              title: title,
              text: text,
              position: 'top-center',
            })
          },
        }
    }
</script>
